<template>
  <div
    :class="[$style.contextmenu, {[$style.disabled]: disabled}]"
    role="button"
    aria-label="Очистить"
  >
    <slot />
    <div
      v-if="buttons.length"
      :class="$style.arrowWrapper"
    >
      <img
        :class="$style.arrow"
        src="@/assets/images/icons/navigation/arrow.svg"
        alt=""
      >
    </div>
    <div
      v-if="buttons.length"
      :class="$style.popup"
    >
      <button
        v-for="(button, idx) in buttons"
        :key="idx"
        :class="$style.button"
        :disabled="button.disabled"
        @click="button.onClick"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</template>

<script>
const hasProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => ([]),
      validator: (value) => {
        for (let i = 0; i < value.length; i++) {
          const item = value[i];
          const valid = hasProperty(item, 'label')
            && hasProperty(item, 'onClick');
            // prop "disabled" is optional

          if (!valid) {
            return false;
          }
        }

        return true;
      },
    },
  },
};
</script>

<style lang="scss" module>
.contextmenu {
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    .popup {
      opacity: 1;
      z-index: 1;
      transition: .15s ease-out;
    }

    .arrow {
      transition: .3s ease-out;
      transform: rotate(180deg);
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .5;
  }
}

.arrowWrapper {
  flex-shrink: 0;
}

.arrow {
  transition: .3s ease-out 1s;
}

.popup {
  position: absolute;
  opacity: 0;
  transition: .3s ease-out 1s;
  z-index: -1;
  right: 0;
  top: 100%;
  white-space: nowrap;
  background: $white;
  padding: 15px;
  box-shadow: $shadow;
}

.button {
  border: 0;
  padding: 5px 15px;
  background: $white;
  display: block;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    background: $light-gray;
    color: $blue;
  }

  &:not(:last-child) {
    margin-bottom: 7.5px;
  }
}
</style>
